// Get the div with id 'laptop'
var laptopDiv = document.getElementById('laptop');

var modalheader = document.getElementById('h2modal-header');

var modalcontent = document.getElementById('pmodal-content');

// Check if the div exists
if (modalheader) {
    // Change the text content of the div
    modalheader.textContent = 'You have filled up the form values and been idle for a long time!!';
    modalcontent.textContent = 'Would you like to submit the form? If Yes, then close the pop up and click on the change password button';
   
} else {
    
}